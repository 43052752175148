<template>
  <!-- 路由占位符 -->
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
html,body,#app{
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
