import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'
import store from './store'

const app = createApp(App)

// axios配置
const options = ''
const instance = axios.create({
  baseURL: options,
  timeout: 5000
})

app.config.globalProperties.$axios = instance
app.use(router)
app.use(store)
app.mount('#app')
